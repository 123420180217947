import axios from "axios";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
if (window.location.hostname == "localhost") {
	window.api_url = "https://darkside_api.test";//"http://localhost:8000";
} else {
	window.api_url = "https://api.darkside-developments.com";
}
if(cookies.get("user")) {
	window.token = cookies.get("user");
} else {
	window.token = null;
}
const Auth_URL = window.api_url + "/api/auth";

Date.prototype.addHours = function(h) {
	this.setHours(this.getHours() + h);
	return this;
};

class AuthService {
	login(user) {
		return axios
			.post(Auth_URL, {
				username: user.username,
				password: user.password,
			})
			.then((response) => {
				if (response.data.success && response.data.success === true) {
					let access_token = response.data.data.user.token;
					window.token = access_token;
					axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
					const expires = new Date().addHours(12).toUTCString();
					if (window.location.hostname === "localhost") {
						cookies.set("user", JSON.stringify(response.data.data.user), expires, null, "localhost", true, 'None');
					} else {
						cookies.set("user", JSON.stringify(response.data.data.user), expires, null, ".darkside-developments.com");
					}
				}
				return response.data;
			});
	}

	logout() {
		cookies.remove("user");
	}

	register(user) {
		return this.$http.post(Auth_URL + "signup", {
			username: user.username,
			email: user.email,
			password: user.password,
		});
	}
}

export default new AuthService();
