import {createRouter, createWebHistory} from 'vue-router'
import {useCookies} from "vue3-cookies";
import axios from 'axios'
const { cookies } = useCookies();

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue'),
        meta: {requiresAuth: false}
    },
    {
        path: '/',
        component: () => import('../views/Frame.vue'),
        meta: {requiresAuth: true},
        children: [
            {
                path: '',
                component: () => import('../views/Home.vue'),
                meta: {requiresAuth: true, id: 1},
                beforeEnter: (to, from, next) => {
                    if(to.fullPath === '/') {
                        const loggedIn = cookies.get("user") || false;
                        if(loggedIn) {
                            next("/queues");
                        } else {
                            next('/login');
                        }
                    } else {
                        next();
                    }
                }
            },
            {
                path: 'queues',
                name: 'Queues',
                component: () => import('../views/Queues.vue'),
                meta: {requiresAuth: true, id: 1}
            },
            {
                path: 'fab-management',
                name: 'Fab Management',
                component: () => import('../views/FabManagement.vue'),
                meta: {requiresAuth: true, id: 1.5}
            },
            {
                path: 'aht',
                name: 'AHT',
                component: () => import('../views/Aht.vue'),
                meta: {requiresAuth: true, id: 2}
            },
            {
                path: 'alt-sku',
                name: 'Alternative SKU\'s',
                id: 3,
                component: () => import('../views/AlternativeSKU.vue'),
                meta: {requiresAuth: true, id: 3}
            },
            {
                path: 'logs',
                name: 'Logs',
                id: 4,
                component: () => import('../views/Logs.vue'),
                meta: {requiresAuth: true, id: 4}
            },
            {
                path: 'low-stock',
                name: 'Low Stock',
                id: 5,
                component: () => import('../views/LowStock.vue'),
                meta: {requiresAuth: true, id: 5}
            },
            {
                path: 'overdue-po',
                name: 'Overdue PO\'s',
                id: 6,
                component: () => import('../views/OverduePO.vue'),
                meta: {requiresAuth: true, id: 6}
            },
            {
                path: 'po-review',
                name: 'Purchase Order Review',
                id: 7,
                component: () => import('../views/POReview.vue'),
                meta: {requiresAuth: true, id: 7}
            },
            {
                path: 'process-fab',
                name: 'Process Fab',
                id: 8,
                component: () => import('../views/ProcessFab.vue'),
                meta: {requiresAuth: true, id: 8}
            },
            {
                path: 'sku-lookup',
                name: 'SKU Lookup',
                id: 9,
                component: () => import('../views/SKULookup.vue'),
                meta: {requiresAuth: true, id: 9}
            },
            {
                path: 'unfulfillable-orders',
                name: 'Unfulfillable Orders',
                id: 10,
                component: () => import('../views/UnfulfillableOrders.vue'),
                meta: {requiresAuth: true, id: 10}
            },
            {
                path: 'preorder',
                name: 'Preorder Items',
                id: 11,
                component: () => import('../views/Preorder.vue'),
                meta: {requiresAuth: true, id: 11}
            },
            {
                path: 'fab',
                name: 'Fabrication',
                id: 12,
                component: () => import('../views/Fab.vue'),
                meta: {requiresAuth: true, id: 12}
            },
            {
                path: 'courier-claims',
                name: 'Courier Claims',
                id: 14,
                component: () => import('../views/CourierClaims.vue'),
                meta: {requiresAuth: true, id: 14}
            },
            {
                path: 'item-aht',
                name: 'Item AHT',
                id: 15,
                component: () => import('../views/ItemAHT.vue'),
                meta: {requiresAuth: true, id: 15}
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
    const authRequired = to.meta.requiresAuth;
    const loggedIn = cookies.get("user") || false;
    document.title = "Dispatch Manager | " + to.name;
    if (authRequired && !loggedIn) {
        localStorage.setItem('redirect', to.fullPath);
        next("/login");
    } else {
        axios.defaults.headers.common["Authorization"] = "Bearer " + loggedIn.token;
        next();
    }
});
export default router
