<template>
    <router-view/>
    <vue-progress-bar></vue-progress-bar>
</template>
<script>
import {defineComponent} from 'vue';
export default defineComponent({
    mounted() {
    }
});
</script>
<style lang="scss">
</style>
