import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'ant-design-vue/dist/antd.css';
import './scss/style.scss';
import Antd from 'ant-design-vue';
import axios from 'axios';
import VueCookies from 'vue3-cookies'
import VueProgressBar from "@aacassandra/vue3-progressbar";
import Sortable from "sortablejs";
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
window.Sortable = Sortable;
const options = {
    color: "#1990ff",
    failedColor: "#d40f0f",
    thickness: "3px",
    transition: {
        speed: "0.1s",
        opacity: "0.8s",
        termination: 300,
    },
    autoRevert: true,
    location: "top",
    inverse: false,
};
const app = createApp(App).use(VueProgressBar, options).use(VueCookies, { expireTimes: "12h"}).use(Antd).use(store).use(router);

const instance = axios.create();
instance.interceptors.request.use(config => {
    app.config.globalProperties.$Progress.start();
    return config;
});

instance.interceptors.response.use(response => {
    app.config.globalProperties.$Progress.finish();
    return response;
});
app.config.globalProperties.$http = instance
app.mount('#app')
